import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aquarius.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Aquarius Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aquarius"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aquarius </h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Aquarius Traits</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aquarius Traits</h1>
                <h2 className="text-sm md:text-base ml-4">Jan 20 - Feb 18</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aquarius Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-love");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-man");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aquarius/aquarius-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aquarius Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

             
              
            </div>
            <span className="my-4 mx-1 md:mx-0">
            Revolutionary in personality and memorable, the Aquarius zodiac sign signifies intellect, spontaneity, and freedom. Aquarians have an out-of-the-box thought and eccentric outlook. They can either be cold and emotionally disconnected or highly emotional. Sometimes their sense is too progressive for some people to comprehend.
<br/><br/>
<p className="">They are a very pro at hiding their genuine feelings, and they have a dark side that they would not bring out. Read on to get an idea of the Aquarius zodiac's classic positive and negative personality traits.</p>
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Let's look at personality traits of positive and negative characteristics of Aquarius</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Positive Traits:</p> Open-Minded, Humanitarian, Innovative, Free Spirited, and Intellectual<br/>
<p className="font-bold text-orange-500 inline-block mr-2">Negative Traits:</p> Capricious, Unexpected, Unpredictable, Extremist, and Stubborn
<br/><br/>

<p className="playfair text-black text-xl md:text-3xl">Aquarius Positive Traits</p><br/>
<p className="playfair text-black text-xl md:text-3xl">Open-Minded</p><br/><br/>
An Aquarius is extraordinarily open-minded and thinks and does things differently than others. They always consider themselves and like to keep an open mind about things. They don't want to judge a book by its cover.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Humanitarian</p><br/>
This zodiac sign defines a different humanitarian of all the 12 zodiac signs. They are forgiving and empathetic and appreciate working for the service of humanity. They care about society more than any other zodiac sign. Aquarius has a massive heart and a soft corner for all the humanitarian issues of the world.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Innovative</p><br/>
Aquarians have an innovative mindset. They take dignity in using art as a way to describe themselves. Due to their self-dependent nature, Aquarius never want to follow the crowd, and this is why they succeed in the areas of creativity.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Spirited</p><br/>
An Aquarius is a real free spirit indicating any attempts to maintain them from being who they are will create them to turn away. As an air sign, they need their own space and liberty to perform their groundbreaking concepts.
<br/><br/>

<p className="playfair text-black text-xl md:text-3xl">Intellectual</p><br/>
They are intellectual genius and can surprise others with their authentic thoughts. 
<br/><br/>
Aquarians are intelligent revolutionaries and will loudly protect their opinions. They have a tremendous capability to initiate and comprehend abstract concepts.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Extremist</p><br/>
Aquarius does things beyond the limits. Even their anger is also above one's imagination. <br/>
They can be unfortunate, sad, agitated, and happy. Aquarians are known as extremist people.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Stubborn</p><br/>
As open-minded as they are, Aquarius is a set character. This means that they are stubborn people, and it is usually challenging to change their minds about something once it is set for. They are very persistent when it comes to their opinions.
<br/><br/>
Let's understand an Aquarius's characteristics will help you make better relationships.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Aquarius Love And Relationships</p> <br/>
Aquarius in love cares to surprise and amazement when least expected. Aquarians are a little amusing when it arrives to loving. For Aquarians, their loved ones are significant and memorable. Ruled by the planet Uranus, Aquarians are imaginative and innovative in love. Aquarius in a relationship are often slow to commit because trusting others is not easy.
<br/><br/>

This air sign faces hardship in counterbalancing the intimacy of love, and they tend to intellectualize emotions, which makes it challenging for them. Aquarius in a connection may not be able to reveal their devotion, and they might not say, "I love you." However, they will show their love in a unique way that they think is right for them and make their loved ones feel special. Aquarian are those people who are in love and would like to spend some quality time with someone they love and would surprise them.
<br/><br/>

<p className="playfair text-black text-xl md:text-3xl">Aquarius Friends And Family</p><br/>
Those bearing the Aquarius Zodiac Sign do not develop close friendships quickly with everyone, even though they can socialize well with people. They usually prefer companions or friends who are creative, genuine, and intellectually inclined. They are constantly ready to try unique things with their buddies. Having a companion with this sign is like being blessed because Aquarians would walk an extra mile and even surrender themselves to their friends. This kind of nature makes them different from others. 
<br/><br/>
Aquarians are the revolutionaries in the family who always have something insightful to convey. Their happiness is always on priority because they do not need money to be happy. Good friends and a loving family are enough for them. They are the kind of people who are protective of their loved ones. Aquarians are an excellent example of a devoted person. They want nobody more than to have amazing friends and a loving family.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Aquarius and Career</p><br/>
The Aquarius star sign individuals are known for their intelligence and creative thinking. They love to improve their knowledge and have the zeal to learn new things. The essential qualities they seek in their profession and job are space, flexibility, innovative outlet, supporting team, growth, and thankfulness. 

<br/><br/>
Aquarians won't receive anything or anyone standing in the way of victory because they know what they are destined to succeed. They are very attentive and focused on development, growth, equality, and reality. They are born to rule! <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Would you like to read further about Aquarius Career?</p><br/>
Read more!
<br/><br/>
 
<p className="playfair text-black text-xl md:text-3xl">Aquarius and Money</p><br/>
Aquarians are not as money-hungry as corresponding to some other zodiac signs. They don't view finances as extremely vital but rather as a point to fulfill needs, not more than that. Following their practical reasons is more significant than how much money they can make. But if they have cash, they love to spend on those things which are the best things money can buy. They would probably invest in start-ups, new inventions, and products.
<br/><br/>

<p className="font-bold text-orange-500 inline-block mr-2">How To Attract Aquarius:</p><br/>
It's not too hard to get an Aquarius person on your side. Here are the clever tips that show you how you can attract Aquarius.
<br/><br/>

<p className="font-bold text-orange-500 inline-block mr-2">Be Communicative:</p><br/>
Good communication is the key to attracting Aquarians. They always need communication compatibility better than anything. Enthrall them in a warm, humorous conversation, take them somewhere different or exciting, but leave them alone when they want to be. Keep things upbeat, and don't be clingy with them at foremost.
<br/><br/>

<p className="font-bold text-orange-500 inline-block mr-2">Don't nag or complain too much:</p><br/>

Never nag Aquarians regarding their mistakes. They will recognize one readily enough but then forget it. An error seems insignificant to them, and if you keep harping on it, they will think you are unknown.
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Ask for their opinions about things:</p><br/>
The trait that attracts them a lot is common sense, and they love people who use <br/><br/>
common sense and can figure out things. Charm them with exciting topics. Show that you have somewhat of a rebel side. Ask them queries and take their views about things and make them feel as if they are exceptional to you.
<br/><br/>
Now that you learn more about the Aquarius personality traits find out how you match with other Zodiac Signs.<br/>

<p className="playfair text-black text-xl md:text-3xl">Compatible Signs Aquarius Should Consider</p><br/>
As an independent, quirky Aquarius with humanitarian intuitions, you need a companion who understands your deep devotion to people and wishes to make the world more suitable. 
<br/><br/>
Here is the list of the most compatible signs with Aquarius are generally considered to be Sagittarius, Libra, and Gemini.
<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
